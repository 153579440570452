// @ts-check
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { MultistreamRequestList } from '../MultistreamRequestList';
import Checkbox from '../Form/Checkbox';
import { updateCurrentUser, sendMultiRequest, SENDING_MULTISTREAM_INVITE, fetchMultiRequests } from '~/modules/currentUser';
import StreamAdultToggle from '../StreamAdultToggle';
import { isLoading } from '~/modules/loading';

import styles from './index.scss';

/**
 * @type {React.FC}
 */
export const QuickSettings = () => {
  const dispatch = useDispatch();
  const { data: currentUser, multistream } = useSelector(state => state.currentUser);
  const sendingInvite = useSelector(state => isLoading(state.loading, SENDING_MULTISTREAM_INVITE));
  const onChangePrivate = useCallback(e => dispatch(updateCurrentUser({
    stream: {
      settings: {
        private: {
          enabled: e.target.checked,
        },
      },
    },
  })), []);

  const onChangeListed = useCallback(e => dispatch(updateCurrentUser({
    stream: {
      settings: {
        basic: {
          listed: e.target.checked,
        },
      },
    },
  })), []);

  const savePrivateParams = useCallback(async (e) => {
    e.preventDefault();
    const form = new FormData(e.target);

    dispatch(updateCurrentUser({
      stream: {
        settings: {
          private: {
            password: form.get('password'),
            description: form.get('description'),
          },
        },
      },
    }, { silent: false }));
  }, []);

  const toggleStreamAdult = useCallback(e => dispatch(updateCurrentUser({
    stream: {
      adult: e.target.checked,
    },
  })), [dispatch]);

  useEffect(() => {
    dispatch(fetchMultiRequests());
  }, []);

  if (!currentUser || !currentUser.stream.settings) {
    return (
      <div className={styles.QuickSettings}>
        Whoa, looks like you&apos;re not signed in!
      </div>
    );
  }

  const isPrivate = currentUser.stream['isPrivate?'];
  const isListed = currentUser.stream.settings.basic.listed;


  return (
    <div className={styles.QuickSettings}>
      <h3 className={styles.QuickSettings__Heading}>Quick settings</h3>

      <h4 className={styles.QuickSettings__Heading}>
        <FormattedMessage
          id="StreamMultistream_Subheading"
          defaultMessage="Multistream"
        />
      </h4>

      <form
        className={styles.MultistreamForm}
        onSubmit={(e) => {
          e.preventDefault();
          dispatch(sendMultiRequest(e.target.targetUsername.value));
        }}
      >
        <input placeholder="Type a username" type="text" required name="targetUsername" />
        <button disabled={sendingInvite} type="submit">
          <FormattedMessage
            id="StreamMultistream_Invite"
            defaultMessage="Invite"
          />
        </button>
      </form>
      {
        multistream.sent.length ? <MultistreamRequestList type="sent" multistream={multistream} /> : null
      }

      {
        multistream.received.length ? <MultistreamRequestList type="received" multistream={multistream} /> : null
      }

      <Checkbox disabled={!currentUser['premium?']} className={styles.Checkbox} onChange={onChangePrivate} name="stream.private" checked={isPrivate}>
        <FormattedMessage
          id="StreamPrivacy_Private"
          defaultMessage="Make my stream private"
        />
      </Checkbox>

      {
        isPrivate && (
          <form onSubmit={savePrivateParams}>
            <label htmlFor="QSPrivateDescription">
              Private description
              <input id="QSPrivateDescription" name="description" type="text" defaultValue={currentUser.stream.settings.private.description} />
            </label>

            <label htmlFor="QSPrivatePassword">
              Stream password
              <input id="QSPrivatePassword" name="password" type="password" defaultValue={currentUser.stream.settings.private.password} />
            </label>

            <button type="submit">
              Save
            </button>
          </form>
        )
      }

      <Checkbox className={styles.Checkbox} disabled={!currentUser['premium?']} onChange={onChangeListed} name="stream.private" checked={isListed}>
        <FormattedMessage
          id="StreamPrivacy_isListed"
          defaultMessage="Show stream in list"
        />
      </Checkbox>

      <StreamAdultToggle
        className={styles.Checkbox}
        onChange={toggleStreamAdult}
        checked={currentUser.stream.adult}
      />
    </div>
  );
};

export default QuickSettings;
