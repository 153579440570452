// @ts-check
/* node_modules */
import React from 'react';
import propTypes from 'prop-types';
import { withRouter } from '~/hooks';

import classnames from 'classnames/bind';

/* local imports */
import config from '~/config';

/* style imports */
import styles from './StreamPreview.scss';

const cx = classnames.bind(styles);

function getStreamThumbnailURL(stream, extension = 'jpg') {
  return `${config.previews}/thumbnails/stream_${
    stream.id
  }.${extension}`;
}

export class StreamPreview extends React.Component {
  static propTypes = {
    /**
     * @type {propTypes.Requireable<Partial<Stream>>}
     */
    stream: propTypes.shape({
      live: propTypes.bool,
      user: propTypes.shape({
        username: propTypes.string,
      }),
      adult: propTypes.bool,
      viewers: propTypes.number,
      blur: propTypes.bool,
    }).isRequired,
    className: propTypes.string,
  }

  static defaultProps = {
    className: '',
  }

  constructor(props) {
    super(props);

    // TODO: clean up state.image
    this.state = {
      image: getStreamThumbnailURL(this.props.stream),
    };
  }

  handleClick(username, event) {
    if (!this.props.history) {
      return;
    }

    event.preventDefault();

    if (event.button === 1) {
      // open in new tab
      window.open(`/watch/${username}`, '_blank');
    } else {
      this.props.history.push(`/watch/${username}`);
    }
  }

  render() {
    /**
     * @type {{ stream: Stream, className?: string }}
     */
    const { stream, className } = this.props;
    const {
      live, adult, user, viewers, blur, username
    } = stream;
    const isPrivate = stream['isPrivate?'];

    // Load background image from screenshot if live, from offline image if available.
    const backgroundImage = live || isPrivate
      ? 'transparent'
      : 'url(/img/offline_thumbnail.jpeg) no-repeat center/cover';
    const privateImage = '/img/private_thumbnail.png';
    const { image: liveImage } = this.state;

    return (
      <a
        onClick={this.handleClick.bind(this, username)}
        href={`/watch/${username}`}
        style={{ background: backgroundImage }}
        className={cx({
          [className]: className,
          StreamPreview: true,
          'StreamPreview--offline': !live,
          'StreamPreview--adult': adult,
        })}
      >
        <div className={styles.StreamPreview__Mask} />

        {isPrivate ? (
          <img alt="Private stream" src={privateImage} className={styles.StreamPreview__Image} />
        ) : (
          live && (
            <picture className={cx({
              StreamPreview__Image: true,
              'StreamPreview__Image--blur': blur,
              nsfw: adult,
            })}>
              <source type="image/webp" srcSet={getStreamThumbnailURL(stream, 'webp')} />

              <img
                alt={`${username}'s stream thumbnail`}
                src={liveImage}
              />
            </picture>
          )
        )}

        <div className={styles.StreamPreview__Inner}>
          <h3>{username}</h3>
        </div>
        {live && (
          <div className={styles.StreamPreview__ViewerCount}>
            <span className="ion-eye" />
            {' '}
            {viewers}
          </div>
        )}
      </a>
    );
  }
}

/**
 * @type {StreamPreview}
 */
export default withRouter(StreamPreview);
