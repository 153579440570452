import React from 'react';
// import thunk from 'redux-thunk';
import { hydrateRoot } from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';

import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';

import { enableBatching } from 'redux-batched-actions';

import * as Sentry from '@sentry/react';
import Modal from 'react-modal';
import rootReducer from './modules';
import { thunk } from './middleware';
import intializeAnalytics from './analytics';
import { Routes } from '~/routes';
import { RouterContext } from './routerContext';

export const renderClientSideApp = () => {
  const preloadedState = window.__PRELOADED_STATE__;
  delete window.__PRELOADED_STATE__;
  
  const sentryReduxEnhancer = Sentry.createReduxEnhancer();

  const store = createStore(
    enableBatching(rootReducer),
    preloadedState,
    compose(applyMiddleware(thunk), sentryReduxEnhancer),
  );
  
  
  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      dsn: 'https://dfb45ef3ceeb9af40d6ce11544eea148@sentry.piczel.tv/4',
      tracesSampleRate: 0.3,
      integrations: [
        new Sentry.Integrations.TryCatch({
          setTimeout: true,
          setInterval: true,
          requestAnimationFrame: true,
          XMLHttpRequest: false,
          eventTarget: true,
        }),
        new Sentry.Integrations.Breadcrumbs({
          console: true,
          dom: true,
          fetch: false,
          history: true,
          sentry: true,
          xhr: false,
        }),
      ],
    });
    intializeAnalytics();
  }

  const router = createBrowserRouter(Routes);

  hydrateRoot(
    document.getElementById('react-root'),
    <Provider store={store}>
      <RouterContext.Provider value={router}>
        <RouterProvider router={router} fallbackElement={null} />
      </RouterContext.Provider>
    </Provider>,
  );

  Modal.setAppElement(document.getElementById('react-root'));
};

export default renderClientSideApp;
