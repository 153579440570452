/* node_modules */
import React, { Component } from 'react';
import { useLocation } from 'react-router-dom';
import propTypes from 'prop-types';
import Helmet from 'react-helmet';
import classNames from 'classnames/bind';

/* local imports */
import Header from './Header';
import ConnectedFlashDrawer from '~/components/FlashDrawer';

/* style imports */
import styles from './Layout.scss';

const cx = classNames.bind(styles);

function Layout({ children }) {
  const { pathname } = useLocation();
  const isWatch = /watch/i.test(pathname);
  const fullWidth = isWatch || /chat/i.test(pathname);

  return (
    <div className={cx('Layout', { 'Layout--noPadding': fullWidth })}>
      <Helmet defaultTitle="Piczel.tv" titleTemplate="%s - Piczel.tv">
        <meta name="description" content="An art streaming and gallery service with free multistreaming and no ads" />
        <meta name="twitter:card" content={isWatch ? 'summary_large_image' : 'summary'} />
        <meta name="twitter:site" content="@piczeltv" />
        <meta name="twitter:title" content="Piczel.tv" />
        <meta name="twitter:description" content="An art streaming and gallery service with free multistreaming and no ads" />
        <meta name="twitter:image" content="https://piczel.tv/img/piczel.jpg" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://piczel.tv" />
        <meta property="og:description" content="An art streaming and gallery service with free multistreaming and no ads" />
        <meta property="og:site_name" content="Piczel.tv" />
      </Helmet>
      <Header />
      <div className={cx('Layout__Container', {
        'Layout__Container--fullWidth': fullWidth,
      })}
      >
        { !isWatch && <ConnectedFlashDrawer /> }
        {children}
      </div>
    </div>
  );
}

Layout.propTypes = {
  children: propTypes.node.isRequired,
};

export default Layout;
